.cellNameActivity {
  justify-content: flex-start;
  align-items: center;
}

.cellStatus {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.capitalize {
  text-transform: capitalize;
}

.cleanDefaultStyle {
  margin: 0;
  padding: 0;
}
.rowFlexParent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.cellRemainCash {
  padding: 3px 5px;
  align-items: center;
  border-radius: 3px;
}

.colFilterOption {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.width-100 {
  width: 100%;
}

.colFilters {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 30px;
  width: 100%;
}
