.station-font-1 {
  font-size: 1rem;
}

.simulator {
  position: fixed;
  overflow-y: scroll;
  right: 0;
  height: 100vh;
  top: 10rem;
  width: 68%;
  padding-bottom: 10rem;
}

.form-create {
  position: fixed;
  overflow-y: scroll;
  left: 7em;
  top: 10em;
  width: 25%;
  padding-bottom: 30em;
  height: 100vh;
}

.station-font-regular {
  font-weight: 400;
}

.station-font-medium {
  font-weight: 500;
}

.station-font-bold {
  font-weight: 700;
}

.station-font-black {
  font-weight: 900;
}

.station-font-semibold {
  font-weight: 600;
}

.station-font-lh-2 {
  line-height: 2rem;
}
.main-simulator p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', 'sans-serif';
}

.main-simulator html {
  font-size: 16px !important;
}
.station-container a:hover {
  text-decoration: none !important;
  color: lightgray !important;
}

.main-simulator a {
  font-size: 1rem;
  font-family: 'Roboto', 'sans-serif';
}

.main-simulator h1 {
  font-size: 3.5rem;
}

.main-simulator h4 {
  font-size: 1.5rem;
}

.main-simulator h6 {
  font-size: 1rem;
}

.main-simulator button {
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  border-radius: 3px !important;
}

.main-simulator span {
  font-family: 'Roboto', 'sans-serif';
  font-size: 1rem;
}

.website-header-background {
  background-size: cover !important;
}

.h1-website {
  font-size: 0.625rem;
  font-weight: 700;
  text-transform: uppercase;
}

.h2-website-mobile {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.25rem;
}

.h2-website {
  font-size: 2.125rem;
  font-weight: 700;
  line-height: 3rem;
}

.color-select-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
