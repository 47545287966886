.actionNameCellStyle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 200;
  background-color: orange;
  border: 1px solid black;
}

.rowToolbar {
  padding-left: 0px;
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 16px;
}
