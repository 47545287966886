.signup-img {
  margin-top: 5em;
}

.signup-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #2b2d6b;
}

.email-confirmation-text {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  color: #535665;
}

.activation-img {
  margin-top: 5em;
}

.activation-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #2b2d6b;
}

.terms-text {
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 12px;
  color: #535665;
  text-align: center;
}

div.terms-text a:hover {
  color: #2b2d6b !important;
}

.signup-modal .p-dialog-titlebar {
  display: block !important;
}

.p-dialog-footer {
  display: none !important;
}
