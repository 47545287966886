@keyframes animacao_forward_0 {
  from {
    transform: rotateY(-90deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

@keyframes animacao_forward_1 {
  from {
    transform: rotateY(-90deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

@keyframes animacao_forward_2 {
  from {
    transform: rotateY(-90deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

@keyframes animacao_forward_3 {
  from {
    transform: rotateY(-90deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

@keyframes animacao_forward_4 {
  from {
    transform: rotateY(-90deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

@keyframes animacao_forward_5 {
  from {
    transform: rotateY(-90deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

@keyframes animacao_backward_0 {
  from {
    transform: rotateY(90deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

@keyframes animacao_backward_1 {
  from {
    transform: rotateY(90deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

@keyframes animacao_backward_2 {
  from {
    transform: rotateY(90deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

@keyframes animacao_backward_3 {
  from {
    transform: rotateY(90deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

@keyframes animacao_backward_4 {
  from {
    transform: rotateY(90deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

@keyframes animacao_backward_5 {
  from {
    transform: rotateY(90deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

.rotate_forward_0 {
  animation: animacao_forward_0 1s;
}
.rotate_forward_1 {
  animation: animacao_forward_1 1s;
}
.rotate_forward_2 {
  animation: animacao_forward_2 1s;
}
.rotate_forward_3 {
  animation: animacao_forward_3 1s;
}
.rotate_forward_4 {
  animation: animacao_forward_3 1s;
}
.rotate_forward_5 {
  animation: animacao_forward_3 1s;
}

.rotate_backward_0 {
  animation: animacao_backward_0 1s;
}
.rotate_backward_1 {
  animation: animacao_backward_1 1s;
}
.rotate_backward_2 {
  animation: animacao_backward_2 1s;
}
.rotate_backward_3 {
  animation: animacao_backward_3 1s;
}
.rotate_backward_4 {
  animation: animacao_backward_3 1s;
}
.rotate_backward_5 {
  animation: animacao_backward_3 1s;
}
