* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  overflow: auto;
  background-color: #f7f7f7;
}

body,
html {
  font-size: 100%;
}

@media print {
  html,
  body {
    height: auto; /* Use 100% here to support printing more than a single page*/
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }
}

button {
  outline: none !important;
}
.row.custom-gutter {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

[class^='col'].custom-gutter,
[class*=' col'].custom-gutter {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.MuiTableCell-paddingCheckbox:last-child {
  background: #ababab;
  color: white;
}
.content {
  min-height: 250px;
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
/*
main {
    background-color: #f7f7f7;
}

body {
    background-color: #f7f7f7;
} */

button {
  border: 0;
  padding: 0;
  background: inherit;
  font-family: 'Roboto';
}

.form-primary {
  border: 1px solid #ff007b;
  font-family: 'Roboto';
  font-size: 16px;
  border-radius: 0;
  padding: 19px 10px;
  font-weight: 400;
}

.p-button {
  background-color: #ff007b !important;
  border: 0 !important;
}

.invalid-feedback-addition {
  position: absolute;
}

.form-group-addition {
  margin-bottom: 1.5rem;
}

.font-dosis {
  font: 16px OpenSans, Arial, Helvetica, sans-serif;
}

a:hover {
  text-decoration: none;
}

.title {
  font-family: 'Lato';
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.body {
  font-family: 'Lato';
  font-weight: 400;
  font-size: 18px;
  line-height: 42px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.body-title {
  font-family: 'Lato';
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.h2 {
  font-family: 'Lato';
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.h2-bold {
  font-family: 'Lato';
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.h3 {
  font-family: 'Lato';
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.h3-bold {
  font-family: 'Lato';
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.h4 {
  font-family: 'Lato';
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.h4-bold {
  font-family: 'Lato';
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.h5 {
  font-family: 'Lato';
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.h5-bold {
  font-family: 'Lato';
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.h6 {
  font-family: 'Lato';
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.h6-bold {
  font-family: 'Lato';
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}

.h7 {
  font-family: 'Lato';
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: #282828;
  margin: 0;
}
