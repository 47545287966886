.links {
  cursor: pointer;
  border-radius: 8px;
  padding: 5px 10px;
  background-color: white;
}

.links:hover {
  background-color: #f7f7f7;
}
