.header-button {
  color: #fc157c;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 12px;
  border-style: solid;
  border-width: 2px;
  display: inline-block;
  right: 0px !important;
  letter-spacing: 0.2em;
}

.navbar-item {
  padding: 0;
}

.navbar-logo {
}
